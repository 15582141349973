/**=====================
    23. Form CSS Start
==========================**/
form {
  &.needs-validation {
    .dflex {
      input {
        ~ span {
          color: $danger-color;
        }
      }
    }
    .input-group {
      span {
        width: 100%;
      }
    }
    &.tooltip-validation {
      &.validateClass {
        .dflex {
          input {
            ~ span {
              padding: 0.25rem 0.5rem;
              margin-top: 0.1rem;
              font-size: 0.875rem;
              line-height: 1.5;
              color: $white;
              background-color: rgba($danger-color, 0.9);
              border-radius: 0.25rem;
            }
          }
        }
      }
    }
  }
}
.input-group-append, .input-group-prepend{
  display: flex;
  .input-group-text{
    &:first-child{
      border-radius: 4px 0 0 4px;
    }
    + .input-group-text{
      border-radius: 0 4px 4px 0;
    }
  }
}
.form-inline{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  .form-group{
    margin-right: 15px;
  }
}
.mega-form{
  ~ hr, hr{
    background-color: rgba($black , 0.2);
  }
}
.form-control {
  border-color: $light-semi-gray;
  &::-webkit-input-placeholder {
    color: $form-placeholder-color;
    font-size: $form-placeholder-font-size;
    letter-spacing: $form-placeholder-letter-spacing;
    font-weight: $form-placeholder-font-weight;
  }
}
select {
  color: $form-placeholder-color !important;
}
textarea {
  border-radius: 20px !important;
  font-family: $font-nunito, $font-serif !important;
}
.billing-form {
  margin-bottom: -20px;
}
.height-35 {
  height: 35px !important;
}
.theme-form {
  label {
    font-size: 16px;
  }
  .form-control {
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  &.sm-form {
    .form-group {
      margin-bottom: $sm-form-margin-bottom;
    }
  }
  .form-group {
    margin-bottom: $form-group-margin-bottom;
    textarea {
      border-color: $form-input-border-color;
      width: 100%;
      &::-webkit-input-placeholder {
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="url"],
    input[type="file"] {
      height: auto;
      padding-left: 20px;
    }
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="url"],
    input[type="file"],
    select {
      border-color: $form-input-border-color;
      background-color: $form-input-bg-color;
      font-size: $form-placeholder-font-size;
      color: $form-placeholder-color;
      font-family: $font-work-sans, $font-serif;

      &::-webkit-input-placeholder {
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    select.form-control:not([size]):not([multiple]) {
      border-color: $form-input-border-color;
      font-size: $form-placeholder-font-size;
    }
  }
  .rbt-input-multi {
    border-color: $form-input-border-color;
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }
  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "Or Signup With";
      color: $theme-font-color;
      background: $white;
    }
  }
  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: $theme-font-color;
      background: $white;
      content: "Or Login With";
    }
  }
  .btn-fb {
    background-color: $fb;
    color: $white;
    width: 100%;
  }
  .btn-twitter {
    background-color: $twitter;
    color: $white;
    width: 100%;
  }
  .btn-google {
    background-color: $google-plus;
    color: $white;
    width: 100%;
  }
}
.form-inline {
  .form-group {
    margin-right: 15px;
    .col-form-label {
      margin-right: 5px;
    }
  }
}
.search-form {
  position: relative;
  .form-group {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 25px;
      background: #e8ebf2;
      left: 52px;
      top: 10px;
    }
    &:after {
      position: absolute;
      content: "\f002";
      font-family: FontAwesome;
      top: 11px;
      left: 25px;
      color: #8e8e8e;
    }
  }
  input {
    border: 1px solid rgba($primary-color, 0.03);
    padding: 10px 10px 10px 70px;
    border-radius: 50px;
    background-color: rgba($primary-color, 0.03);
  }
}
.form-label-align-right {
  label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.custom-file-label {
  line-height: 1.8;
  &::after {
    line-height: 1.8;
  }
}
.rbt {
  &.btn-pill {
    .rbt-input-multi {
      border-radius: 20px;
    }
  }
}
/**=====================
    23. Form CSS Ends
==========================**/
