/**=====================
     33. Table  CSS Start
==========================**/
.pills-component {
  width: 20%;
}
.product-page-width {
  width: 80%;
}
.table {
  margin-bottom: $table-b-margin;
  th,
  td {
    padding: $table-padding;
  }
  th {
    color: $table-heading-color;
  }
  td {
    color: $table-row-color;
  }
  [class*="bg-"] {
    color: $white;
  }
  .bg-light {
    color: $theme-body-font-color;
  }
  tfoot {
    font-weight: $table-footer-font-weight;
  }
}
.table-inverse {
  th,
  td {
    color: $white;
  }
}
.table[class*="bg-"] {
  th,
  td {
    color: $white;
  }
}
.table-hover {
  tbody {
    tr:hover {
      background-color: $table-hover-color;
    }
  }
}
.thead-dark {
  tr {
    th {
      color: $white;
    }
  }
}
.bg-primary {
  tr {
    th {
      color: $white;
    }
  }
}
.table {
  &.table-primary {
    th,
    td {
      color: $white;
    }
    &.table-hover {
      tr {
        &:hover {
          td {
            color: $theme-body-font-color;
          }
        }
      }
    }
  }
}

//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}
.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}
.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}
.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}
.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}

//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}
.table-border-vertical {
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }
  tr {
    td {
      &:last-child {
        border-right: none;
      }
    }
  }
}

.table-bordernone {
  td {
    border: 0;
  }
  .bd-t-none {
    border-top: none;
  }
  .u-s-tb {
    padding: 11px;
  }
}
.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;
  th,
  td {
    border-color: $white;
  }
  &.table-bordered {
    border: 0;
  }
}
.table-striped {
  > tbody {
    > tr {
      &:nth-of-type(odd) {
        *{
          --bs-table-accent-bg: unset;
          color: $theme-body-font-color;
        }
        background-color: rgba(245, 247, 250, 0.5) !important;
      }
    }
  }
}
.table{
  .thead-dark{
    th{
      background-color: #343a40;
      border-color: #454d55;
    }
  }
  .thead-light{
    th{
      color: #495057 !important;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }
}
.border-bottom-primary{
  th{
    border-bottom: 2px solid $primary-color !important;
  }
}
.table{
  tbody:not(:first-child){
    border-top: 2px solid $horizontal-border-color;
  }
}
.table-border-vertical{
  &.table-bordered{
    th,td{
      border: 1px solid #dee2e6 !important;
    }
    tbody{
      tr{
        &:last-child{
          border-bottom: unset;
        }
      }
    }
  }
  tr,td,th{
    border-bottom: unset;
  }
}
.tables-wrapper{
  .row{
    > div{
      .card{
        overflow: hidden;
        .table-responsive{
          tbody{
            tr{
              &:last-child{
                th, td{
                  border-bottom: unset;
                }
              }
            }
          }
          tfoot{
            tr{
              &:last-child{
                th,td{
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.table-styling{
  &.table-primary{
    &.table-striped{
      tbody{
        tr{
          &:nth-child(even){
            --bs-table-hover-bg: #fdfeff;
          }
        }
      }
    }
    thead{
      th{
        background-color: $primary-color;
      }
    }
    tbody{
      background-color: rgba($light, 0.8) !important;
      td, th,tr{
        background-color: rgba($primary-color, 0.5);
      }
    }
  }
}
.table-responsive{
  >.table-bordered{
    border: transparent;
  }
}
.table{
  &.table-striped{
    tbody{
      border-top: 2px solid #dee2e6;
    }
  }
} 
.table-bordered {
  td,
  th {
    border-color: $light;
  }
}
/**=====================
     33.Table CSS Ends
==========================**/
