/**=====================
     28. pagination CSS Start
==========================**/
/*test Nav bar color */
$page-name: theme, primary, secondary, success, danger, info, dark, warning;
$page-color: $primary-color, $primary-color, $secondary-color, $success-color,
  $danger-color, $info-color, $dark-color, $warning-color;
@each $var in $page-name {
  $i: index($page-name, $var);
  .pagination-#{$var} {
    .page-item {
      .page-link {
        color: nth($page-color, $i);
      }
      &.active {
        .page-link {
          color: $white;
          background-color: nth($page-color, $i);
          border-color: nth($page-color, $i);
        }
      }
    }
  }
}
.page-item {
  &:first-child {
    .page-link {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }
  &:last-child {
    .page-link {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}
.pagination-lg, .pagination-sm{
  .page-item{
    &:first-child{
      .page-link{
        border-radius: 30px 0 0 30px;
      }
    }
    &:last-child{
      .page-link{
        border-radius: 0 30px 30px 0;
      }
    }
  }
}
.pagination{
  .page-item.disabled{
    .page-link{
      padding: 8px 12px;
    }
  }
}
/**=====================
     28. pagination CSS Ends
==========================**/
