/**=====================
     56. FullCalendar CSS Start
==========================**/
.rbc-toolbar {
  justify-content: flex-start !important;
  button {
    border: 1px solid $primary-color !important;
    font-size: 14px;
    &:active,
    &.rbc-active {
      background-color: $primary-color !important;
      box-shadow: none !important;
      color: $white !important;
    }
  }
}
.rbc-today {
  background-color: rgba($primary-color, 0.1) !important;
}
.rbc-header {
  padding: 5px !important;
}
.rbc-month-view {
  height: auto !important;
}
.rbc-event,
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  background-color: $primary-color !important;
}
// draggable calender
#external-events {
  background-color: $transparent-color !important;
  overflow-y: auto !important;
  padding: 10px !important;
  width: 90%;
}
.fc-event,
.fc-event-dot {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  padding: 7px !important;
  color: $white !important;
}
.fc-button-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
/**=====================
    56. FullCalendar CSS End
==========================**/
