$primary-color: #126bc5;
$secondary-color: #f2f3f5;
$warning: orange;

.home {
  &__navbar,
  &__header-section,
  &__about-us,
  &__how-support,
  &__hero-image,
  &__call-section,
  &__sample-campaigns,
  &__sample-campaigns-title
  {
    padding: 1rem 2.5rem 1rem;
  }

  .navbar-toggler {
    background-color: white !important;
  }

  &__navbar--scrolled {
    background-color: white !important;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.16),0 2px 2px 0 rgba(0,0,0,.1) !important;
    
    .nav-item > a {
      color: $primary-color !important;
    }
  }

  &__button,
  &__campaign-donate-button {
    min-width: 120px;
  }

  &__intro,
  &__builder-text {
    text-align: center;
    font-size: 1rem;
    width: 75%;
  }

  &__header-section {
    display: flex;
    align-items: center; 
    background-color: $primary-color; 
    flex-direction: column; 
    color: white;
    text-align: center;
  }

  &__header-section,
  &__call-section {
    -webkit-box-shadow: inset 8px 46px 300px -107px rgba(0,0,0,1);
    -moz-box-shadow: inset 8px 46px 300px -107px rgba(0,0,0,1);
    box-shadow: inset 8px 46px 300px -107px rgba(0,0,0,1);
  }

  &__logo {
    max-height: 2.5rem;
  }

  &__button {
    padding: 0.375rem 1rem !important;
  }

  &__button:hover {
    opacity: 0.8 !important;
  }

  &__campaign-donate-button {
    color: white !important;

    background-color: $primary-color !important;
  }

  &__campaign-donate-button:hover {
    color: $primary-color !important;

    background-color: $secondary-color !important;
  }

  &__button--primary {
    color: white !important;

    background-color: $primary-color !important;
  }

  &__button--secondary {
    background-color: $secondary-color !important;
  }

  &__button--warning {
    color: white !important;
    background-color: $warning !important;
  }

  &__hero-title {
    text-shadow: 1px 1px #000;
    color: #80cf00 !important;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: 3rem !important;
  }

  &__hero-title--variation {
    color: white !important;
  }

  &__intro {
    text-align: start;
    font-size: 1.125rem;
    color: white;
    font-family: 'Roboto';
    font-weight: lighter;
  }

  &__hero-section {
    font-size: 2.125rem;
    height: 700px;
    background-position: '50%';
    background-size: 'cover';
  }

  &__hero-image {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    height: 100%;
    color: white !important;

    background-color: rgba(0, 0, 0, 0.5);
  }

  &__about-us-container {
    margin: 4rem 0rem;
  }

  &__about-us-text {
    background-color: $primary-color;

    border-radius: 20px;
  }

  &__about-us-image {
    min-height: 350px;
    background-image: url("./about-us.jpg");
    background-position: top;
    background-size: cover;
    border-radius: 20px;
  }

  &__call-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: white;

    background-color: $primary-color;
  }

  &__button--call-out {
    color: white !important;
    background-color: $warning !important;

    width: 120px;
  }

  &__footer {
    color: $primary-color;
    font-weight: bold;
    padding: 1rem;
  }

  &__campaign-card {
    margin: 0.5rem !important;
  }

  &__campaign-details {
    padding: 1rem !important;
    display: flex;
    flex-direction: column;
  }

  &__campaign-country {
    padding-bottom: 0.25rem;
  }

  &__campaign-title {
    font-size: 1.25rem;
    font-weight: 800
  }

  &__campaign-progress {
    height: 5px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__how-support {
    background-color: #f2f3f5;
  }

  
  &__how-support-title  {
    color: #126bc5;
  }

  &__sample-campaigns,
  &__sample-campaigns-title {
    color:#126bc5;
  }

  &__sample-campaigns-title {
    padding-top: 4rem;
  }

  &__sample-campaigns {
    padding-bottom: 4rem;
  }

  &__check-icon {
    color: #80cf00 !important;
  }

  &__campaign-card:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }

  @media  (max-width: 800px) {
    &__about-us-container > .col-sm-6 {
      flex: 0 0 auto !important;
      width: 100% !important;
    }

    &__about-us-image {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }
  }

  @media (max-width: 720px) {
    &__intro,
    &__builder-text {
      width: 100%;
    }

    &__hero-title {
      font-size: 2rem !important;
    }
  }

  @media (max-width: 999px) {
    .card-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .card-group > .card {
      flex: 0 0 47%;
    }
  }

  @media (max-width: 640px) {
    .card-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .card-group > .card {
      flex: unset;
    }
  }

  @media (max-width: 575px) {
    &__builder-actions {
      text-align: start;
    }

    &__builder-text {
      text-align: justify;
    }

    &__navbar,
    &__header-section,
    &__about-us,
    &__hero-image,
    &__call-section,
    &__sample-campaigns,
    &__sample-campaigns-title
    {
      padding-inline-start: 0.75rem;
      padding-inline-end: 0.75rem;
    }
  }

  .circle {
    color:white !important;
    padding: 10px;
    background-color: #80cf00;
    border-radius: 50%;
  }
  
  .line {
    border-top: solid #80cf00 5px;
  
    @media (max-width: 575px) { 
      border-top: none;
    }
  }
  
  .icon-offsite {
    margin-top: -15px;
  }

  &__carousel-indicator.carousel-indicators > button {
    width: 15px;
    height: 15px;

    border-radius: 50%;

    .active {
      background-color: $primary-color;
    }
  }

  &__carousel-indicator.carousel-indicators > button.active {
      background-color: #80cf00;
  }
}
