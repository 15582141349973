/**=====================
    55. Box-layout CSS start
==========================**/
[main-theme-layout="box-layout"] {
  @media (min-width: 1280px) {
    .time_picker_wrapper {
      width: 60%;
    }
    .time_picker_wrapper2 {
      .time_picker_container {
        width: 58%;
      }
    }
    .sticky-note{
      .note{
        width: calc(25% + 38px);
      }
    }
    .action-text{
      .alert{
        p{
          max-width: 185px !important;
        }
      }
    }
    .cart{
      .order-history{
        &.wishlist{
          tbody{
            tr{
              &:last-child{
                button.btn{
                  padding: 6px 22px;
                }
              }
            }
          }
          .qty-box{
            width: 35%;
          }
        }
      }
    }
    .vertical-timeline--two-columns {
      .vertical-timeline-element {
        &:nth-child(even) {
          &:not(.vertical-timeline-element--left) {
            .vertical-timeline-element-content {
              .vertical-timeline-element-date {
                right: 128%;
              }
            }
          }
        }
      }
    }
    .vertical-timeline--two-columns{
      .vertical-timeline-element-content{
        .vertical-timeline-element-date{
          left: 128%;
        }
      }
    }
    .checkout{
      .checkout-details{
        padding: 34px;
      }
    } 
    .socialprofile{
      .media{
        img{
          margin-right: 14px;
        }
        .media-body{
          span{
            span{
              &.px-2{
                padding-right: 0 !important;
              }
            }
          }
        }
      }
    }
    .email-right-aside{
      .email-body{
        .inbox{
          .media{
            display: block;
            text-align: center;
          }
        }
      }
    }
    .email-left-aside{
      .email-app-sidebar{
        > .media{
          display: block;
          text-align: center;
        }
      }
    }
    .crypto-graph-card{
      .media{
        .rounded-icon{
          margin-right: 8px;
        }
      }
    }
    .p-watch{
      img{
        margin: 5px 0;
      }
    }
    .monthly-overview{
      .rounds-decore{
        li{
          left: -12px;
        }
      }
    }
    .page-wrapper {
      .Add-card {
        display: none;
      }
      .page-body-wrapper {
        .iconsidebar-menu {
          .iconMenu-bar {
            overflow: unset;
            li {
              position: unset;
              &.open {
                .bar-icons {
                  &:before {
                    display: none;
                  }
                }
              }
            }
            .iconbar-mainmenu {
              animation: fadeIn 1000ms ease-in-out;
            }
            .iconbar-mainmenu {
              position: absolute;
              top: 0;
              display: none;
            }
          }
          &.iconbar-mainmenu-close {
            .iconMenu-bar {
              .iconbar-mainmenu {
                display: none;
                visibility: hidden;
                transition: 0.5s;
              }
            }
          }
          &.iconbar-second-close {
            .iconMenu-bar {
              display: none;
              visibility: hidden;
              transition: 0.5s;
              .iconbar-mainmenu {
                display: none;
                visibility: hidden;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
    .page-wrapper {
      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .page-main-header {
        .main-header-right {
          margin: 0;
        }
      }
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .browse {
          div[class^="col-"] {
            &:nth-child(3) {
              margin-top: 30px;
            }
          }
        }
        .active-order-table {
          max-width: 443px;
          overflow: auto;
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity {
          .media {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        margin: 0 auto;
        footer {
          margin: 0 auto;
          right: 0;
          padding-right: 15px;
          margin-left: 300px;
          z-index: 99;
        }
        .footer-fix {
          margin-left: 567px;
        }
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
              > div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }
              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
              img {
                width: 38%;
              }
            }
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget {
          img {
            height: 65px;
          }
        }
        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      .page-main-header {
        max-width: 1280px;
        // padding-left: 250px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        &.open {
          padding-left: 0;
          transition: $sidebar-transition;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .box-col-4 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .box-col-33 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
      }
      .product-table {
        .rdt_Table {
          .rdt_TableHead {
            .rdt_TableHeadRow {
              .rdt_TableCol {
                min-width: 200px;
              }
            }
          }
          .rdt_TableBody {
            .rdt_TableCell {
              &:last-child {
                min-width: 200px;
              }
            }
          }
        }
      }
      .product-wrapper{
        &.sidebaron{
          .product-sidebar{
            .filter-section{
              .card{
                .left-filter{
                  z-index: 2;
                }
              }
            }
          }
          .product-grid{
            .product-wrapper-grid{
              margin-left: 0;
            }
          }
        }
      } 
      .chat-menu.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        transition: all linear 0.3s;
        padding-bottom: 25px;
      }
      .product-wrapper{
        .product-grid{
          .products-total{
            li{
              a.product-6-layout-view{
                display: none;
              }
            }
          }
        }
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
  }
}
.rtl {
  .box-layout.page-wrapper {
    .page-main-header {
      max-width: 1280px;
      padding-right: 250px;
      padding-left: unset;
      &.open {
        max-width: 1280px;
      }
      .main-header-right {
        .nav-right {
          > ul {
            > li {
              &:first-child {
                .search-form {
                  .form-group {
                    &:after {
                      right: 22px;
                      left: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-body-wrapper {
      footer {
        margin-right: 566px;
        margin-left: unset;
        padding-left: 15px;
        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }
}
/**=====================
    55. Box-layout CSS ends
==========================**/
